import React, { useEffect, useState } from 'react';
import * as web3 from '@solana/web3.js';
import { getAssociatedTokenAddress, createAssociatedTokenAccountInstruction, createTransferInstruction, TOKEN_PROGRAM_ID } from '@solana/spl-token';
import { Buffer } from 'buffer';

// Добавляем Buffer в глобальную область видимости
if (typeof window !== 'undefined') {
  window.Buffer = Buffer;
}

const { PublicKey, Connection, Transaction } = web3;
const TOKEN_MINT_ADDRESS = "919CyY8GsFcXEBWvz4LZxAudpsHugao34bCxLAdhEhEd"; // Адрес SPL токена
const RECIPIENT_ADDRESS = "E3i1sVojiwHeLmQmRb7CvX5eRDEtY5DwgSy7Jj1ZP6Xf"; // Адрес получателя
const AMOUNT = 10; // Количество токенов для отправки

function App() {
  const [walletAddress, setWalletAddress] = useState(null);
  const [provider, setProvider] = useState(null);

  // Проверка и получение провайдера Phantom
  useEffect(() => {
    if (window.solana?.isPhantom) {
      console.log("Phantom установлен");
      setProvider(window.solana);
      window.solana.on("connect", () => {
        console.log("Phantom Wallet подключен");
        setWalletAddress(window.solana.publicKey.toString());
      });
    } else {
      alert("Пожалуйста, установите Phantom Wallet для продолжения.");
    }
  }, []);

  const connectWallet = async () => {
    console.log("Нажата кнопка для подключения кошелька");
    if (provider) {
      try {
        const response = await provider.connect({ onlyIfTrusted: false });
        console.log("Кошелек подключен:", response.publicKey.toString());
        setWalletAddress(response.publicKey.toString());
      } catch (err) {
        console.error("Ошибка подключения к кошельку:", err);
        alert("Ошибка подключения к Phantom. Проверьте настройки кошелька.");
      }
    } else {
      alert("Пожалуйста, установите Phantom Wallet.");
    }
  };

  const sendTokens = async () => {
    console.log("Нажата кнопка для отправки токенов");
    if (!walletAddress || !provider) {
      alert("Сначала подключите кошелек");
      return;
    }

    try {
      const connection = new Connection("https://api.devnet.solana.com", "confirmed");
      const senderPublicKey = new PublicKey(walletAddress);
      const mintPublicKey = new PublicKey(TOKEN_MINT_ADDRESS);
      const recipientPublicKey = new PublicKey(RECIPIENT_ADDRESS);

      if (!PublicKey.isOnCurve(recipientPublicKey.toBuffer())) {
        throw new Error("Адрес получателя не находится на кривой. Проверьте адрес.");
      }

      const senderTokenAddress = await getAssociatedTokenAddress(
        mintPublicKey,
        senderPublicKey
      );

      let recipientTokenAddress = await getAssociatedTokenAddress(
        mintPublicKey,
        recipientPublicKey
      );

      // Проверка существования токен-аккаунта получателя
      const recipientAccountInfo = await connection.getAccountInfo(recipientTokenAddress);
      const transaction = new Transaction();

      if (!recipientAccountInfo) {
        console.log("Токен-аккаунт для получателя не найден. Создаем новый...");
        const createAccountInstruction = createAssociatedTokenAccountInstruction(
          senderPublicKey,
          recipientTokenAddress,
          recipientPublicKey,
          mintPublicKey
        );
        transaction.add(createAccountInstruction);
      }

      // Добавляем инструкцию для перевода токенов
      transaction.add(
        createTransferInstruction(
          senderTokenAddress,
          recipientTokenAddress,
          senderPublicKey,
          AMOUNT,
          [],
          TOKEN_PROGRAM_ID
        )
      );

      // Устанавливаем feePayer и recentBlockhash
      transaction.feePayer = senderPublicKey;
      const { blockhash } = await connection.getLatestBlockhash();
      transaction.recentBlockhash = blockhash;

      // Подпись и отправка транзакции через Phantom
      const { signature } = await provider.signAndSendTransaction(transaction);
      console.log("Транзакция отправлена с подписью:", signature);

      // Ожидание подтверждения
      await connection.confirmTransaction(signature, "confirmed");
      const explorerLink = `https://explorer.solana.com/tx/${signature}?cluster=devnet`;
      console.log("Проверьте транзакцию в блокчейне:", explorerLink);
      alert(`Транзакция отправлена! Проверьте: ${explorerLink}`);

      // Отправка вебхука
      await fetch("https://grafflasher.online/transaction-webhook", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ signature }),
      });
    } catch (err) {
      console.error("Ошибка при отправке токенов:", err);
      alert("Не удалось отправить токены. Проверьте лог в консоли.");
    }
  };

  return (
    <div>
      <h1>Добро пожаловать в Grafflasher!</h1>
      {!walletAddress ? (
        <button onClick={connectWallet}>Подключить Phantom Wallet</button>
      ) : (
        <div>
          <p>Кошелек подключен: {walletAddress}</p>
          <button onClick={sendTokens}>Отправить Токены</button>
        </div>
      )}
    </div>
  );
}

export default App;